import React, { createContext, useState, useEffect } from 'react';
import { getProfile } from '../services/profile';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState({ loaded: false });

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getProfile();
      if (response) {
        setState({
          loaded: true,
          cookieDomain: response.cookieDomain,
          iotPortalUrl: response.iotPortalUrl,
          loginUrl: response.loginUrl,
          user: response.user
        });
      } else {
        setState({ loaded: true });
      }
    };

    fetchUser();
  }, []);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
