import React, { createContext, useState, useEffect } from 'react';
import { getSimOffers } from '../services/product';

export const ProductsContext = createContext();

const ProductsContextProvider = ({ children }) => {
  let params = new URLSearchParams(document.location.search.substring(1));
  let currency = params.get('currency') || 'EUR';
  const [products, setProducts] = useState({ products: [] });

  useEffect(() => {
    const fetchData = async () => {
      const simOffers = await getSimOffers(currency);
      setProducts(simOffers);
    };

    fetchData();
  }, [currency]);

  return <ProductsContext.Provider value={products}>{children}</ProductsContext.Provider>;
};

export default ProductsContextProvider;
