import React, { createContext, useReducer } from 'react';
import { CartReducer, sumItems } from './CartReducer';

export const CartContext = createContext();

const storage = sessionStorage.getItem('cart') ? JSON.parse(sessionStorage.getItem('cart')) : [];
const initialState = { cartItems: storage, ...sumItems(storage) };

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const setQuantity = (payload) => {
    dispatch({ type: 'SET_QUANTITY', payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: 'ADD_ITEM', payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const itemInCart = (product) => {
    return state.cartItems.find((item) => item.id === product.id) != null;
  };

  const trialInCart = () => {
    return state.cartItems.find((item) => item.trial);
  };
  const getCartItem = (product) => {
    return state.cartItems.find((item) => item.id === product.id);
  };

  const contextValues = {
    removeProduct,
    addProduct,
    setQuantity,
    itemInCart,
    getCartItem,
    trialInCart,
    ...state
  };

  return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
};

export default CartContextProvider;
