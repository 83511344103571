import HeronSansWoff from '../fonts/HeronSansCondBold.woff';

const heron = {
  fontFamily: 'HeronSansCond',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
      local('HeronSansCond'),
      local('HeronSansCondBold'),
      url('${HeronSansWoff}') format('woff')
    `
};

export default [heron];
