import React from 'react';
import Header from './components/Header/Header';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Routing } from './navigation';
import { makeStyles } from '@material-ui/core/styles';
import ThemeProvider from './theme/themeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthContextProvider from './contexts/AuthContext';
import ProductsContextProvider from './contexts/ProductsContext';
import CartContextProvider from './contexts/CartContext';
import UserProfileContextProvider from './contexts/UserProfileContext';
import { SnackbarProvider } from 'notistack';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px'
  },
  headerLogo: {
    display: 'inline-flex'
  },
  appBarSpacer: theme.mixins.toolbar
}));

if (process.env.NODE_ENV !== 'production') {
  let params = new URLSearchParams(document.location.search.substring(1));
  let jwt = params.get('jwt');
  if (jwt) {
    const cookies = new Cookies();
    cookies.set('jwt_token_tp', jwt);
  }
}

export default function App() {
  const classes = useStyles();

  return (
    <Router>
      <AuthContextProvider>
        <UserProfileContextProvider>
          <ProductsContextProvider>
            <CartContextProvider>
              <ThemeProvider>
                <div className={classes.root}>
                  <CssBaseline />
                  <Header />
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <Routing />
                  </SnackbarProvider>
                </div>
              </ThemeProvider>
            </CartContextProvider>
          </ProductsContextProvider>
        </UserProfileContextProvider>
      </AuthContextProvider>
    </Router>
  );
}
