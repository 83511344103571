import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { Button, Link as MaterialLink, IconButton } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import SupportMenu from './SupportMenu';
import HeaderLoggedIn from './HeaderLoggedIn';
import { AuthContext } from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  headerLogo: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '150px',
    maxWidth: '50%',
    '& > img': {
      maxWidth: '100%'
    }
  },
  buttons: {
    maxWidth: '50%',
    whiteSpace: 'nowrap',
    marginLeft: 'auto'
  },
  header: {
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    borderBottom: '1px solid rgba(13, 49, 63, 0.1)',
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: '0 2rem'
    }
  }
}));

function Header() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);

  return (
    <header className={classes.header}>
      <MaterialLink href="/" className={classes.headerLogo}>
        <img src="/images/truphone-logo.svg" alt="" />
      </MaterialLink>
      <HeaderButtonGroup align="right" alignItems="center" className={classes.buttons}>
        <SupportMenu />

        {authContext.loaded && authContext.user && (
          <HeaderLoggedIn cookieDomain={authContext.cookieDomain} {...authContext.user} />
        )}

        {!authContext.user && (
          <Button
            href={`${authContext.loginUrl}?redirectUrl=${window.location.origin}`}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<PersonIcon />}
          >
            Sign in
          </Button>
        )}
      </HeaderButtonGroup>
    </header>
  );
}

function HeaderButtonGroup(props) {
  const { align, ...rest } = props;
  return (
    <Box display="flex" {...rest}>
      {props.children}
    </Box>
  );
}

function HeaderIcon(props) {
  const { href, classes, ...rest } = props;
  return (
    <IconButton href={href} classes={classes}>
      <img {...rest} alt="" />
    </IconButton>
  );
}

export default Header;
export { HeaderButtonGroup, HeaderIcon };
