import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import PrivateRoute from './routes/PrivateRoute';

const SelectSim = React.lazy(() => import('./page/SelectSim'));
const Checkout = React.lazy(() => import('./page/Checkout'));
const Summary = React.lazy(() => import('./page/Summary'));
const Success = React.lazy(() => import('./page/Success'));

const useStyles = makeStyles((theme) => ({
  content: {
    background: '#f7f6f6'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

const EmptyLayout = (props) => {
  return <>{props.children}</>;
};

const MainLayout = (props) => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <Container maxWidth="lg" fixed className={classes.container}>
        {props.children}
      </Container>
    </main>
  );
};

export function Routing() {
  return (
    <ErrorBoundary>
      <Switch>
        <Route path={['/success']}>
          <EmptyLayout>
            <Route path={'/success'} component={Success} />
          </EmptyLayout>
        </Route>
        <Route path={['/checkout', '/summary', '/']}>
          <MainLayout>
            <Switch>
              <Route exact path={'/'} component={SelectSim} />
              <PrivateRoute path={'/checkout'} component={Checkout} />
              <PrivateRoute path={'/summary'} component={Summary} />
            </Switch>
          </MainLayout>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}
