import React, { createContext, useReducer, useEffect } from 'react';
import { getProfileDetails } from '../services/profile';
import UserProfileReducer from './UserProfileReducer';

export const UserProfileContext = createContext();

let initialValues = { loaded: false };
if (sessionStorage.getItem('userProfile')) {
  initialValues = Object.assign(initialValues, JSON.parse(sessionStorage.getItem('userProfile')));
  initialValues.loaded = true;
}

const UserProfileContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserProfileReducer, initialValues);

  useEffect(() => {
    const getUserDetailsAsync = async () => {
      const profileDetails = await getProfileDetails();
      if (profileDetails) {
        dispatch({
          type: 'PROFILE_LOADED',
          payload: {
            personalInformation: {
              firstName: profileDetails.profile.firstName,
              lastName: profileDetails.profile.lastName,
              email: profileDetails.profile.email,
              phone: profileDetails.profile.phone,
              companyName: profileDetails.profile.companyName,
              jobTitle: profileDetails.profile.jobTitle,
              countryOfRegistration: profileDetails.profile.countryOfRegistration,
              companyNumber: profileDetails.profile.companyNumber,
              type: profileDetails.profile.type
            },
            shippingAddress: { ...profileDetails.profile.shippingAddress },
            sameAsShipping: true,
            billingAddress: { ...profileDetails.profile.billingAddress },
            useSavedCard: !!profileDetails.paymentMethod,
            savedPaymentMethod: profileDetails.paymentMethod,
            paymentInformation: {
              nameOnCard: '',
              cardNumber: '',
              expiryMonth: '',
              expiryYear: '',
              cvc: '',
              saveCard: false
            }
          }
        });
      }
    };

    if (!state.loaded) {
      getUserDetailsAsync();
    }
  }, [state.loaded]);

  const updateState = (payload) => {
    dispatch({ type: 'UPDATE_PROFILE', payload });
  };

  const contextValues = {
    updateState,
    ...state
  };

  return (
    <UserProfileContext.Provider value={contextValues}>{children}</UserProfileContext.Provider>
  );
};

export default UserProfileContextProvider;
