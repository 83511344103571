const valid = require('card-validator');

const persistToSessionStorage = (profile) => {
  sessionStorage.setItem('userProfile', JSON.stringify(profile));
};

const summarizeAddress = (address) => {
  let addressSummary = '';
  if (address.streetAddress1) {
    addressSummary += `${address.streetAddress1}, `;
  }

  if (address.streetAddress2) {
    addressSummary += `${address.streetAddress2}, `;
  }

  if (address.city) {
    addressSummary += `${address.city}, `;
  }

  if (address.state) {
    addressSummary += `${address.state}, `;
  }

  if (address.postCode) {
    addressSummary += `${address.postCode}, `;
  }

  if (address.country) {
    addressSummary += `${address.country}`;
  }

  return addressSummary;
};

const setCardType = (cardNumber) => {
  const cardValidation = valid.number(cardNumber);
  return cardValidation.card ? cardValidation.card.type : '';
};

const UserProfileReducer = (state, action) => {
  switch (action.type) {
    case "PROFILE_LOADED": 
      state = Object.assign(state, action.payload);
      if (!state.loaded) {
        state.loaded = true;
      }

      persistToSessionStorage(state);
      return { ...state };
    case 'UPDATE_PROFILE':
      if (action.payload) {
        if (action.payload.personalInformation) {
          Object.getOwnPropertyNames(action.payload.personalInformation).forEach(prop => {
            state.personalInformation[prop] = action.payload.personalInformation[prop];
          });
        }

        if (action.payload.shippingAddress) {
          Object.getOwnPropertyNames(action.payload.shippingAddress).forEach(prop => {
            state.shippingAddress[prop] = action.payload.shippingAddress[prop];
          });
          state.shippingAddress.summary = summarizeAddress(state.shippingAddress);
          if (state.sameAsShipping) {
            state.billingAddress.summary = summarizeAddress(state.shippingAddress)
          }
        }

        if (action.payload.sameAsShipping !== undefined) {
          state.sameAsShipping = action.payload.sameAsShipping;
        }

        if (action.payload.billingAddress) {
          Object.getOwnPropertyNames(action.payload.billingAddress).forEach(prop => {
            state.billingAddress[prop] = action.payload.billingAddress[prop];
          });
          state.billingAddress.summary = summarizeAddress(
            state.sameAsShipping ? state.shippingAddress : state.billingAddress);
        }

        if (action.payload.useSavedCard !== undefined) {
          state.useSavedCard = action.payload.useSavedCard;
        }

        if (action.payload.paymentInformation) {
          Object.getOwnPropertyNames(action.payload.paymentInformation).forEach(prop => {
            state.paymentInformation[prop] = action.payload.paymentInformation[prop];
          });

          if (state.paymentInformation.cardNumber) {
            state.paymentInformation.type = setCardType(state.paymentInformation.cardNumber)
          }
        }
      }

      persistToSessionStorage(state);
      return { ...state };
    default:
      return { ...state };
  }
};

export default UserProfileReducer;
