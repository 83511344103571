import React from 'react';
import Typography from '@material-ui/core/Typography';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.log('error', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Typography variant={'body1'}>Oops! Something went really wrong</Typography>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
