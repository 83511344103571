import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  Typography,
  Link,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import { AuthContext } from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  name: {
    textAlign: 'left',
    marginLeft: theme.spacing(2)
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  icon: {
    width: 30,
    height: 25,
    color: theme.colors.colorTransparent,
    marginLeft: 20,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  popupContent: {
    minWidth: 290,
    padding: theme.spacing(2)
  },
  profileLarge: {
    width: 60,
    height: 60,
    margin: 'auto',
    fontSize: '1.8rem'
  },
  link: {
    marginTop: -theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  },
  bottomButtons: {
    justifyContent: 'space-between',
    backgroundColor: theme.colors.grey1,
    '&> button': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}));

export default function HeaderLoggedIn(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const authContext = useContext(AuthContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logout = () => {
    const cookies = new Cookies();
    cookies.set('jwt_token_tp', null, { expires: new Date(), domain: props.cookieDomain });
    cookies.set('jwt_refresh_token_tp', null, { expires: new Date(), domain: props.cookieDomain });

    sessionStorage.clear();
    window.location.replace('/');
  };

  return (
    <>
      <ButtonBase
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.root}
      >
        {props.firstName !== undefined && props.lastName !== undefined && (
          <Avatar alt={`${props.firstName} ${props.lastName}`}>
            {`${props.firstName && props.firstName[0]}${props.lastName && props.lastName[0]}`}
          </Avatar>
        )}
        <Box className={` ${classes.name} ${classes.hideMobile} `}>
          <Typography variant="body2">
            {props.firstName} {props.lastName}
          </Typography>
          {props.jobTitle && (
            <Typography variant="subtitle1" color="textSecondary">
              {props.jobTitle}
            </Typography>
          )}
        </Box>
        <ExpandMoreIcon className={classes.icon} />
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'top-end' ? 'center top' : 'right top' }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper>
                <Box
                  className={classes.popupContent}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  textAlign="center"
                >
                  <Avatar
                    className={classes.profileLarge}
                    alt={`${props.firstName} ${props.lastName}`}
                  >
                    {props.firstName && props.firstName[0]}
                    {props.lastName && props.lastName[0]}
                  </Avatar>
                  <Typography variant="h3">
                    {props.firstName} {props.lastName}
                  </Typography>
                  <Link
                    className={classes.link}
                    color="textSecondary"
                    href={`mailto:${props.email}`}
                    variant="h5"
                  >
                    {props.email}
                  </Link>
                  <Button
                    fullWidth
                    variant="outlined"
                    href={authContext.iotPortalUrl}
                  >
                    Manage
                  </Button>
                </Box>
                <Box className={classes.bottomButtons} display="flex">
                  <Button variant="text" onClick={() => logout()}>
                    Log out
                  </Button>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}
