import colors from './colors';
import fonts from './fonts';

export default () => {
  return {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [...fonts]
      }
    },
    MuiInputBase: {
      input: {
        '&:-internal-autofill-selected': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: '1.5px',
        padding: '0 2rem',
        height: '36px'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        },
        '&:hover': {
          boxShadow: 'none'
        }
      },
      containedPrimary: {
        '&:hover': {
          color: colors.white
        }
      },
      outlined: {
        padding: '0 2rem',
        border: '2px solid' + colors.grey2
      },
      sizeLarge: {
        padding: '0 2rem',
        lineHeight: 1.2,
        height: '52px',
        minWidth: '12rem'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.blue4,
        opacity: 0.1
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 4px 5px 0 rgba(13, 49, 63, 0.05), 0 1px 5px 0 rgba(13, 49, 63, 0.05)'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: colors.blue5 + '!important'
        }
      },
      input: {
        padding: '11.5px 14px'
      }
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: colors.blue5
        }
      },
      outlined: {
        transform: 'translate(14px, 14px) scale(1)'
      }
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: colors.blue5
      }
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid' + colors.grey3
      },
      flexContainer: {
        backgroundColor: colors.white
      }
    },
    MuiTab: {
      root: {
        letterSpacing: '1.5px'
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 36,

        '@media (min-width: 600px)': {
          minHeight: 36,
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '2rem',
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      },
      icon: {
        fontSize: '2.6rem',
        top: 'calc(50% - 20px)',
        color: colors.blue5
      }
    }
  };
};
