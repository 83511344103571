import fetchRefresh from './fetchRefresh';

const getProfile = async () => {
  return await fetchRefresh(`api/profile`);
};

const getProfileDetails = async () => {
  return await fetchRefresh(`api/profile/details`);
};

const getPaymentDetails = async (id) => {
  return await fetchRefresh(`api/profile/paymentmethod?id=${id}`);
};

export { getProfile, getProfileDetails, getPaymentDetails };
