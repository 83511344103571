import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const authContext = useContext(AuthContext);
  const handleAuthentication = () => {
    if (authContext.loaded) {
      if (authContext.user != null) {
        return <Component />
      } else {
        const loginUrl = new URL(authContext.loginUrl);
        loginUrl.searchParams.set('redirectUrl', window.location.toString());
        window.location.replace(loginUrl);
      }
    }
  };

  return <Route {...rest} render={handleAuthentication} />;
};

export default PrivateRoute;