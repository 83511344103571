const Storage = (cartItems) => {
  sessionStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems : []));
};

export const sumItems = (cartItems) => {
  Storage(cartItems);
  let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
  let total = cartItems
    .reduce((total, product) => total + product.price * product.quantity, 0)
    .toFixed(2);
  return { itemCount, total };
};

export const CartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      let items = [...state.cartItems];

      //Remove any existing trial SIM
      if (action.payload.trial) {
        const existingTrial = items.find((item) => item.trial);
        if (existingTrial) {
          items.splice(items.indexOf(existingTrial), 1);
        }
      }

      let existingProduct = items.find((item) => item.id === action.payload.id);
      if (existingProduct) {
        existingProduct.quantity += action.payload.quantity;
      } else {
        if (action.payload.trial) {
          items.unshift({
            ...action.payload,
            quantity: action.payload.quantity || 1
          });
        } else {
          items.push({
            ...action.payload,
            quantity: action.payload.quantity || 1
          });
        }
      }

      return {
        ...state,
        ...sumItems(items),
        cartItems: [...items]
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        ...sumItems(state.cartItems.filter((item) => item.id !== action.payload.id)),
        cartItems: [...state.cartItems.filter((item) => item.id !== action.payload.id)]
      };
    case 'SET_QUANTITY':
      state.cartItems[state.cartItems.findIndex((item) => item.id === action.payload.id)].quantity =
        action.payload.quantity;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems]
      };
    default:
      return state;
  }
};
