import React from 'react';
import { CircularProgress, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((_) => ({
  root: {
    'flex-direction': 'column',
    'align-items': 'center',
    'justify-content': 'center'
  },
  centered: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    'margin-top': '-140px'
  }
}));

export default function AppLoading() {
  const classes = useStyles();
  return (
    <Box height="100vh" width="100%" className={classes.root}>
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    </Box>
  );
}
