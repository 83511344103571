export default {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: '52px'
  },
  h2: {
    fontFamily: 'HeronSansCond',
    fontSize: '2.125rem',
    letterSpacing: '2px'
  },
  h3: {
    fontSize: '1.5rem',
    lineHeight: '1.5'
  },
  h4: {
    fontSize: '.875rem',
    letterSpacing: '1.5px'
  },
  h5: {
    fontSize: '14px',
    lineHeight: 1.43
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 700
  },
  body1: {
    fontSize: '1rem'
  },
  subtitle1: {
    fontSize: '.75rem',
    letterSpacing: '0.3px',
    fontWeight: 'normal'
  },
  subtitle2: {
    fontSize: '.875rem',
    letterSpacing: '.5px',
    fontWeight: 'bold'
  }
};
