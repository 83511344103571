import Cookies from 'universal-cookie';
const cookies = new Cookies();

const refreshToken = async () => {
  const response = await fetch(`api/profile/refresh`, { method: 'POST' });
  return response.ok;
};

const expireSessionCookies = () => {
  cookies.remove('jwt_token_tp');
  cookies.remove('jwp_refresh_token_tp');
};

const fetchRefresh = async (url, options) => {
  options = options || {};

  let loginUrl;
  const response = await fetch(url, options);
  if (response.ok) {
    return await response.json();
  } else if (response.status === 401) {
    loginUrl = response.headers.get('x-login-url');

    if (cookies.get('jwt_refresh_token_tp')) {
      const refreshed = await refreshToken();
      if (refreshed) {
        return await fetchRefresh(url, options);
      }
    }
  }

  expireSessionCookies();
  //If we get here the user is nolonger authenticated.
  window.location.assign(`${loginUrl}?redirectUrl=${window.location}`);
};

export default fetchRefresh;
