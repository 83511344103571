const colors = {
  // truphone blue tones
  blue1: '#2a9ec2',
  blue2: '#30819e',
  blue3: '#20667f',
  blue4: '#104c5f',
  blue5: '#01313f',

  secondary: '#01313f',

  // truphone neutral tones
  cream: '#f7f6f6',

  // truphone grey tones
  grey1: '#f3f6f6',
  grey2: '#e8ecee',
  grey3: '#dde3e5',
  grey4: '#d1d9dc',
  grey5: '#c6ccce',
  grey7: '#a1a0a1',
  grey9: '#767c7e',

  black: '#000000',
  white: '#ffffff',

  colorTransparent: 'rgba(13, 49, 63, .5)',
  colorTransparent1: 'rgba(13, 49, 63, .3)',
  colorTransparent2: 'rgba(13, 49, 63, .65)',

  // truphone context colors
  success: '#04c453',
  error: '#e74243',
  warning: '#f57c00'
};

export default colors;
